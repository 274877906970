import { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import * as sc from "../styled/scSlider";
import { participants } from "../contants";
import TwitterIcon from "../assets/twitter.svg";
import InstagramIcon from "../assets/instagram.svg";
import PrevIcon from "../assets/prevIcon.svg";
import NextIcon from "../assets/nextIcon.svg";

const SliderItem = ({
  img,
  name,
  description,
  linkedin,
  twitter,
  instagram,
}) => (
  <sc.SliderItem>
    <sc.SliderItemImage src={img} alt={name} />
    <sc.SliderItemMain>
      <sc.SliderItemDetails>
        <sc.SliderItemDetailsHeading>{name}</sc.SliderItemDetailsHeading>
        <sc.SliderItemDetailsText>{description}</sc.SliderItemDetailsText>
      </sc.SliderItemDetails>
      <sc.SliderItemSocials>
        <sc.SliderItemSocialLink href={twitter}>
          <sc.SliderItemSocialLinkImage
            src={TwitterIcon}
            alt={`Twitter - ${name}`}
          />
        </sc.SliderItemSocialLink>
        <sc.SliderItemSocialLink href={instagram}>
          <sc.SliderItemSocialLinkImage
            src={InstagramIcon}
            alt={`Instagram - ${name}`}
          />
        </sc.SliderItemSocialLink>
      </sc.SliderItemSocials>
    </sc.SliderItemMain>
  </sc.SliderItem>
);

const Slider = () => {
  const [swiper, setSwiper] = useState();

  return (
    <sc.SliderContainer>
      <sc.SliderPrevButton
        onClick={() => swiper?.slideTo(swiper?.activeIndex - 1)}
      >
        <sc.SliderButtonIcon src={PrevIcon} alt="Önceki" />
      </sc.SliderPrevButton>
      <sc.SliderNextButton
        onClick={() => swiper?.slideTo(swiper?.activeIndex + 1)}
      >
        <sc.SliderButtonIcon src={NextIcon} alt="Sonraki" />
      </sc.SliderNextButton>
      <Swiper
        onSwiper={(swiper) => setSwiper(swiper)}
        grabCursor
        slidesPerView={1}
        breakpoints={{
          1200: {
            slidesPerView: 3,
          },
          768: {
            slidesPerView: 2,
          },
        }}
      >
        {participants.map((participant, i) => (
          <SwiperSlide key={i}>
            <SliderItem {...participant} />
          </SwiperSlide>
        ))}
      </Swiper>
    </sc.SliderContainer>
  );
};

export default Slider;
