import styled from "styled-components";
import { colors } from "../contants";

export const SliderContainer = styled.div`
  padding-top: 7.5rem;
  position: relative;

  .swiper {
    width: 95%;
    margin: 0 auto;

    @media only screen and (max-width: 1200px) {
      width: 90%;
    }

    @media only screen and (max-width: 768px) {
      width: 85%;
    }
  }
`;

export const SliderPrevButton = styled.button`
  position: absolute;
  top: 47.5%;
  left: -2.5rem;
  transform: translateY(-50%);
  background: transparent;
  outline: none;
  border: none;
  cursor: pointer;
  z-index: 10000;
  transition: opacity 0.5s ease;

  &:hover {
    opacity: 0.5;
  }

  @media only screen and (max-width: 1200px) {
    left: 0;
    transform: translateY(-50%) scale(0.9);
  }

  @media only screen and (max-width: 768px) {
    left: -1rem;
    transform: translateY(-50%) scale(0.7);
  }
`;

export const SliderNextButton = styled.button`
  position: absolute;
  top: 47.5%;
  right: -2.5rem;
  transform: translateY(-50%);
  background: transparent;
  outline: none;
  border: none;
  cursor: pointer;
  z-index: 10000;
  transition: opacity 0.5s ease;

  &:hover {
    opacity: 0.5;
  }

  @media only screen and (max-width: 1200px) {
    right: 0;
    transform: translateY(-50%) scale(0.9);
  }

  @media only screen and (max-width: 768px) {
    right: -1rem;
    transform: translateY(-50%) scale(0.7);
  }
`;

export const SliderButtonIcon = styled.img``;

export const SliderItem = styled.div`
  width: 100%;
  padding: 0 3.5rem;

  @media only screen and (max-width: 1200px) {
    padding: 0 5rem;
  }

  @media only screen and (max-width: 1024px) {
    padding: 0 3.5rem;
  }

  @media only screen and (max-width: 768px) {
    padding: 0 2.5rem;
  }
`;

export const SliderItemImage = styled.img`
  height: 35rem;
  width: 100%;
  object-fit: cover;
  border-radius: 1.5rem;

  @media only screen and (max-width: 1200px) {
    height: 45rem;
  }

  @media only screen and (max-width: 768px) {
    height: 65rem;
  }

  @media only screen and (max-width: 550px) {
    height: 45rem;
  }

  @media only screen and (max-width: 400px) {
    height: 35rem;
  }
`;

export const SliderItemMain = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 1.5rem;
  text-align: left;
`;

export const SliderItemDetails = styled.div``;

export const SliderItemDetailsHeading = styled.h3`
  color: white;
  font-weight: 400;
  font-size: 1.7rem;
  line-height: 1;
  margin-bottom: 5px;
`;

export const SliderItemDetailsText = styled.p`
  font-weight: 300;
  font-size: 1.4rem;
  color: ${colors.textColor};
`;

export const SliderItemSocials = styled.div`
  flex-shrink: 0;
`;

export const SliderItemSocialLink = styled.a`
  &:not(:last-child) {
    margin-right: 1rem;
  }
`;

export const SliderItemSocialLinkImage = styled.img``;
