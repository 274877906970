import Logo from "../assets/gelisimLogo.png";

import TanerCagliImage from "../assets/taner-cagli.jpg";
import GurayAydinImage from "../assets/guray-aydin.jpg";
import IhsanCanitezImage from "../assets/ihsan-canitez.png";
import TahaUlukayaImage from "../assets/taha-ulukaya.jpg";
import ErtanSinanSahinImage from "../assets/ertan-sinan-sahin.jpg";
import SaboImage from "../assets/sabo-pasa.jpg";

import InstagramIcon from "../assets/footerInstagram.svg";
import LinkedinIcon from "../assets/footerLinkedin.svg";
import TwitterIcon from "../assets/footerTwitter.svg";

import Biletinial from "../assets/biletinial.png";
import Jerfl from "../assets/jerf.png";
import KenevirCo from "../assets/kenevirco.png";
import Taleworlds from "../assets/taleworlds.png";
import Muhiko from "../assets/muhiko.png";
import Hop from "../assets/hop.png";
import YvesRocher from "../assets/yvesrocher.png";
import Dardanel from "../assets/dardanel.png";
import MedusaAtelier from "../assets/medusaatelier.png";
import SerinceCigKofte from "../assets/serincecigkofte.png";

export const eventProviderLink = "https://www.acmhacettepe.com";
export const eventProvider = "ACM Hacettepe";
export const eventName = (
  <>
    Gelişim <span>V10</span>
  </>
);
export const eventDate = "25-26 Aralık 2023";
export const eventLogo = Logo;
export const eventDetails =
  "ACM Hacettepe Öğrenci Topluluğu olarak her sene düzenlediğimiz GELİŞİM etkinliği, 25-26 Aralık 2023 tarihlerinde düzenlenecek GELİŞİM V10 ile devam ediyor. Her sene olduğu gibi bu yılki GELİŞİM etkinliği de alanında uzman katılımcıların tecrübeleri, geçmişleri ve bilgi birikimleri üzerine yapacakları sunumlarla öğrencilerin hayatlarına farklı bakış açıları getirmeyi hedefliyor.";
export const contactMessage =
  "Etkinlik hakkında herhangi bir istek, öneriniz veya merak ettikleriniz hakkında bize mail yolu ile ulaşabilirsiniz.";
export const contactMailLink = "mailto:iletisim@acmhacettepe.com";

export const navigationItems = [
  {
    name: "Anasayfa",
    to: "#home",
  },
  {
    name: "Hakkımızda",
    to: "#about",
  },
  {
    name: "Konuşmacılar",
    to: "#participants",
  },
  {
    name: "Takvim",
    to: "#calendar",
  },
];

export const footerSocialLinks = [
  {
    link: "https://twitter.com/acmhacettepe?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor",
    icon: TwitterIcon,
  },
  {
    link: "https://www.instagram.com/acmhacettepe/",
    icon: InstagramIcon,
  },
  {
    link: "https://www.linkedin.com/company/acm-hacettepe/mycompany/",
    icon: LinkedinIcon,
  },
];

export const dates = ["25 Aralık", "26 Aralık"];

export const participants = [
  {
    name: "İhsan Canıtez",
    description: "Karikatürist",
    date: dates[0],
    time: "12:30",
    detail: "",
    img: IhsanCanitezImage,
  },
  {
    name: "Taner Çağlı",
    description: "İngilizcenin Matematiğini Yazan Adam",
    date: dates[0],
    time: "14:00",
    detail: "",
    img: TanerCagliImage,
  },
  {
    name: "Güray Aydın",
    description: "Doktor",
    date: dates[0],
    time: "15:30",
    detail: "",
    img: GurayAydinImage,
  },
  {
    name: "Eran Sinan Şahin",
    description: "Fizikçi",
    date: dates[1],
    time: "12:30",
    detail: "Fizik Adam <3",
    img: ErtanSinanSahinImage,
  },
  {
    name: "Sabri Can Onay Yontar",
    description: "İçerik Üreticisi",
    date: dates[1],
    time: "14:00",
    detail: "Sabo Paşa <3",
    img: SaboImage,
  },
  {
    name: "Taha Ulukaya",
    description: "Yönetmen/Senarist",
    date: dates[1],
    time: "15:30",
    detail: "",
    img: TahaUlukayaImage,
  },
];

export const sponsors = [
  Biletinial,
  Jerfl,
  KenevirCo,
  Taleworlds,
  Muhiko,
  Hop,
  YvesRocher,
  Dardanel,
  MedusaAtelier,
  SerinceCigKofte,
];

export const colors = {
  primaryColor: "#c80815",
  secondaryColor: "#5F7EDE",
  secondaryColorLight: "rgba(95, 126, 222, .1)",
  textColor: "rgba(255, 255, 255, .5)",
  borderColor: "rgba(255, 255, 255, .1)",
  popupColor: "#F0F3FB",
  popupOverlayColor: "rgba(0, 0, 0, .3)",
  dark300: "#22202B",
  dark400: "#1D1B24",
  dark500: "#17151D",
};
