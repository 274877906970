import styled from "styled-components";
import { colors } from "../contants";

export const HeaderWrapper = styled.header`
  position: sticky;
  top: 0;
  z-index: 100000;
  padding: 2rem 0;
  transition: padding 0.5s ease, background 0.5s ease, border-bottom 0.5s ease;
  transform: translateY(-100%);

  ${(props) =>
    props.$sticky &&
    `
    padding: 5px 0;
    background: rgba(23, 21, 29, 0.89);
    backdrop-filter: blur(9.7px);
    -webkit-backdrop-filter: blur(9.7px);
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  `}
`;

export const HeaderContainer = styled.div`
  width: 95%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 2rem;
  margin: 0 auto;

  @media only screen and (min-width: 1500px) {
    max-width: 115rem;
  }

  @media only screen and (max-width: 600px) {
    width: 100%;
  }
`;

export const HeaderLogoLink = styled.a`
  display: inline-block;
  transition: transform 0.5s ease;
  cursor: pointer;

  ${(props) =>
    !props.$sticky &&
    `
    &:hover {
      transform: translateY(-5px);
    }
  `}
`;

export const HeaderLogo = styled.img`
  width: 12.5rem;
`;

export const HeaderNav = styled.ul`
  display: flex;
  align-items: center;

  @media only screen and (max-width: 1024px) {
    display: none;
  }
`;

export const HeaderItem = styled.li`
  list-style: none;

  &:not(:last-child) {
    margin-right: 4rem;
  }
`;

export const HeaderItemLink = styled.button`
  font-size: 1.4rem;
  font-weight: 300;
  color: ${colors.textColor};
  transition: color 0.5s ease;
  cursor: pointer;

  &:hover {
    color: ${colors.secondaryColor};
  }
`;

export const HeaderButton = styled.button`
  cursor: pointer;
  color: white;
  background-color: ${colors.primaryColor};
  padding: 1.25rem 3.5rem;
  font-size: 1.4rem;
  font-weight: 400;
  border-radius: 1rem;
  text-decoration: none;
  transition: background-color 0.5s ease;

  @media only screen and (max-width: 600px) {
    padding: 1.2rem 2.75rem;
  }

  &:hover {
    background-color: ${colors.secondaryColor};
  }
`;
