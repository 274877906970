import { createGlobalStyle } from "styled-components";
import { colors } from "../contants";

export const GlobalStyles = createGlobalStyle`
  *, *::before, *::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  html {
    font-size: 62.5%;
    font-family: 'Poppins', sans-serif;

    @media only screen and (max-width: 1024px) {
      font-size: 60%;
    }

    @media only screen and (max-width: 600px) {
      font-size: 57.5%;
    }
  }

  button {
    border: none;
    outline: none;
    background: transparent;
    font-family: 'Poppins', sans-serif;
  }

  body {
    background-color: ${colors.dark500};
    width: 100%;
    min-height: 100vh;
  }
`;
