import styled from "styled-components";
import { colors } from "../contants";

export const HeroWrapper = styled.section`
  position: relative;
  padding: 6rem 0;
`;

export const HeroContainer = styled.div`
  position: relative;
  z-index: 1;
  text-align: center;
`;

export const HeroGradientBg = styled.img`
  position: absolute;
  bottom: -15rem;
  left: 50%;
  width: 100%;
  transform: translateX(-50%);
  opacity: 0;

  @media only screen and (max-width: 1024px) {
    bottom: -7.5rem;
  }

  @media only screen and (max-width: 600px) {
    bottom: 0;
  }
`;

export const HeroStarBg = styled.img`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  max-width: 100rem;
`;

export const HeroInfo = styled.div``;

export const HeroTitle = styled.h1`
  color: white;
  font-weight: bold;
  font-size: 6.3rem;
  font-family: "Syne", "Poppins", sans-serif;
  text-align: center;

  @media only screen and (max-width: 1024px) {
    font-size: 5.4rem;
  }

  @media only screen and (max-width: 600px) {
    font-size: 4.4rem;
  }

  @media only screen and (max-width: 400px) {
    font-size: 3.6rem;
  }

  & > div {
    position: relative;
    display: inline-block;
    opacity: 0;
    will-change: transform;
    perspective: 1000px;
    transform-style: preserve-3d;
    transform: scale(0.6) translate(0, 10rem) translate3d(0, 0, 0)
      rotateX(-100deg) skew(0, -5deg);
  }
`;

export const HeroTag = styled.span`
  color: ${colors.secondaryColor};
  font-size: 1.5rem;
  background-color: ${colors.secondaryColorLight};
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 22.5rem;
  height: 5.5rem;
  border-radius: 1rem;
  font-weight: 400;
  margin-top: 3rem;
  overflow: hidden;
  opacity: 0;
  transform: scaleX(0.2);

  .hero-tag-inner {
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translateY(4rem);
  }

  @media only screen and (max-width: 1024px) {
    font-size: 1.4rem;
  }
`;

export const HeroTagImage = styled.img`
  margin-right: 1rem;
`;

export const HeroImageContainer = styled.div`
  margin-top: 12.5rem;

  @media only screen and (max-width: 600px) {
    margin-top: 7.5rem;
  }
`;

export const HeroImage = styled.img`
  width: 80%;
  max-width: 40rem;
  transform: scale(0.5);
  opacity: 0;
`;
