import * as sc from "../styled/scHero";
import StarBg from "../assets/starBg.svg";
import DateIcon from "../assets/dateBlue.svg";
import GradientBg from "../assets/gradientBg.svg";
import { eventName, eventDate, eventLogo, eventProvider } from "../contants";

const Hero = () => {
  return (
    <sc.HeroWrapper>
      <sc.HeroGradientBg className="hero-gradient-bg" src={GradientBg} />
      <sc.HeroStarBg src={StarBg} />
      <sc.HeroContainer>
        <sc.HeroInfo>
          <sc.HeroTitle className="hero-heading">
            <div className="hero-heading-div hero-upper-heading-animation">
              {eventProvider}
            </div>
            <br />
            <div className=" hero-heading-div hero-lower-heading-animation">
              {eventName}
            </div>
          </sc.HeroTitle>
          <sc.HeroTag className="hero-tag">
            <div className="hero-tag-inner">
              <sc.HeroTagImage src={DateIcon} alt={eventDate} />
              {eventDate}
            </div>
          </sc.HeroTag>
        </sc.HeroInfo>
        <sc.HeroImageContainer>
          <sc.HeroImage
            className="hero-image"
            src={eventLogo}
            alt={eventProvider}
          />
        </sc.HeroImageContainer>
      </sc.HeroContainer>
    </sc.HeroWrapper>
  );
};

export default Hero;
