import * as sc from "../styled/scAbout";
import Slider from "./Slider";
import { eventName, eventDetails } from "../contants";

const About = () => {
  return (
    <sc.AboutWrapper id="about">
      <sc.AboutContainer>
        <sc.AboutTitle>{eventName}</sc.AboutTitle>
        <sc.AboutText>{eventDetails}</sc.AboutText>
        <div id="participants">
          <Slider />
        </div>
      </sc.AboutContainer>
    </sc.AboutWrapper>
  );
};

export default About;
