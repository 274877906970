import styled from "styled-components";
import { colors } from "../contants";

export const ProgramWrapper = styled.section`
  padding: 5rem 0;

  @media only screen and (max-width: 1024px) {
    padding: 0;
  }
`;

export const ProgramContainer = styled.div`
  width: 95%;
  max-width: 115rem;
  background-color: ${colors.dark400};
  border-radius: 1.5rem;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  overflow: hidden;

  @media only screen and (min-width: 1025px) {
    min-height: 71rem;
  }

  @media only screen and (max-width: 1024px) {
    flex-direction: column;

    & > div {
      width: 100%;
    }
  }
`;

export const ProgramMain = styled.div`
  width: 52.5%;
  padding: 8rem 10rem 8rem 7.5rem;

  @media only screen and (max-width: 1200px) {
    padding: 8rem 5rem 8rem 5rem;
  }

  @media only screen and (max-width: 1024px) {
    padding: 7.5rem 5rem;
  }

  @media only screen and (max-width: 500px) {
    padding: 5rem 2.5rem;
  }
`;

export const ProgramHeading = styled.h3`
  font-size: 5.4rem;
  font-family: "Syne", "Poppins", sans-serif;
  font-weight: bold;
  color: white;

  @media only screen and (max-width: 1024px) {
    font-size: 4.8rem;
  }

  @media only screen and (max-width: 600px) {
    font-size: 3.6rem;
  }
`;

export const ProgramButtonContainer = styled.div`
  display: flex;
  width: 100%;
  margin: 3.5rem 0 4rem;

  @media only screen and (max-width: 1024px) {
    margin-bottom: 3rem;
  }

  @media only screen and (max-width: 600px) {
    margin: 2.75rem 0 2rem;
  }
`;

export const ProgramButton = styled.button`
  color: ${(props) => (props.$active ? "white" : colors.textColor)};
  background-color: ${(props) =>
    props.$active ? colors.primaryColor : colors.dark300};
  font-size: 1.4rem;
  font-weight: 400;
  border-radius: 1rem;
  transition: background-color 0.5s ease;
  padding: 1.4rem 3rem;
  cursor: pointer;

  @media only screen and (max-width: 600px) {
    padding: 1.2rem 2.5rem;
    font-size: 1.3rem;
  }

  ${(props) =>
    !props.$active &&
    `
    &:hover {
      background-color: ${colors.secondaryColor};
    }
  `}

  &:not(:last-child) {
    margin-right: 2rem;
  }
`;

export const ParticipantsContainer = styled.div``;

export const Participant = styled.div`
  display: flex;
  align-items: center;
  padding: 3rem 0;
  position: relative;
  z-index: 0;
  transition: opacity 0.5s ease;
  cursor: pointer;

  &:hover {
    & > img {
      opacity: 0.5;
    }
  }

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: -20rem;
    width: 155%;
    height: 100%;
    background-color: ${colors.dark300};
    z-index: -1;
    border-radius: 0 1.5rem 1.5rem 0;
    transition: transform 0.5s ease;
    transform: ${(props) => (props.$active ? "none" : "translateX(-155%)")};

    @media only screen and (max-width: 1200px) {
      width: 147.5%;
      transform: ${(props) => (props.$active ? "none" : "translateX(-147.5%)")};
    }

    @media only screen and (max-width: 1100px) {
      width: 152.5%;
      transform: ${(props) => (props.$active ? "none" : "translateX(-152.5%)")};
    }

    @media only screen and (max-width: 1024px) {
      display: none;
    }
  }
`;

export const ParticipantContent = styled.div``;

export const ParticipantImage = styled.img`
  width: 6rem;
  height: 6rem;
  object-fit: cover;
  border-radius: 50%;
  flex-shrink: 0;
  transition: opacity 0.5s ease;

  @media only screen and (max-width: 600px) {
    width: 4.8rem;
    height: 4.8rem;
  }
`;

export const ParticipantDetails = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  margin-left: 2rem;

  @media only screen and (max-width: 500px) {
    margin-left: 1.5rem;
  }
`;

export const ParticipantName = styled.h4`
  color: white;
  font-size: 1.6rem;
  font-weight: 400;

  @media only screen and (max-width: 600px) {
    font-size: 1.5rem;
  }
`;

export const ParticipantDescription = styled.p`
  color: ${colors.textColor};
  font-size: 1.4rem;
  font-weight: 300;

  @media only screen and (max-width: 600px) {
    font-size: 1.3rem;
  }
`;

export const ParticipantProgramDate = styled.span`
  color: ${colors.secondaryColor};
  background-color: ${colors.secondaryColorLight};
  margin-left: 3rem;
  font-size: 1.25rem;
  padding: 6px 1.2rem;
  border-radius: 5px;
  display: flex;
  align-items: center;

  @media only screen and (max-width: 600px) {
    font-size: 1.2rem;
  }
`;

export const ParticipantProgramDateIcon = styled.img`
  width: 1.5rem;
  height: 1.5rem;
  object-fit: cover;
  margin-right: 7.5px;
  transform: translateY(-1px);
`;

export const ProgramDetails = styled.div`
  background-color: ${colors.dark300};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 47.5%;

  @media only screen and (max-width: 1024px) {
    display: none;
  }
`;

export const DetailsParticipantImage = styled.img`
  width: 16rem;
  height: 16rem;
  object-fit: cover;
  border-radius: 50%;
`;

export const DetailsParticipantName = styled.h4`
  color: white;
  font-size: 2.4rem;
  font-weight: 400;
  line-height: 1.3;
  margin: 1.5rem 0 2px;
`;

export const DetailsParticipantDescription = styled.p`
  color: ${colors.textColor};
  font-size: 1.6rem;
  font-weight: 300;
`;

export const DetailsParticipantDivider = styled.hr`
  border: 0.5px solid ${colors.borderColor};
  width: 5rem;
  margin: 1.5rem 0;
`;

export const DetailsParticipantAbout = styled.p`
  color: ${colors.textColor};
  font-size: 1.4rem;
  font-weight: 300;
  max-width: 30rem;
  text-align: center;
  line-height: 1.8;
`;
