import styled from "styled-components";
import { colors } from "../contants";

export const AboutWrapper = styled.section`
  padding: 7.5rem 0;
`;

export const AboutContainer = styled.div`
  width: 95%;
  max-width: 115rem;
  text-align: center;
  margin: 0 auto;
  position: relative;
`;

export const AboutTitle = styled.h2`
  font-size: 4.2rem;
  font-family: "Syne", "Poppins", sans-serif;
  font-weight: bold;
  color: white;
  margin-bottom: 2.5rem;

  span {
    color: ${colors.primaryColor};
  }

  @media only screen and (max-width: 1024px) {
    font-size: 3.6rem;
  }

  @media only screen and (max-width: 600px) {
    font-size: 3rem;
  }
`;

export const AboutText = styled.p`
  width: 90%;
  max-width: 75rem;
  margin: 0 auto;
  color: ${colors.textColor};
  font-size: 1.4rem;
  line-height: 1.6;
`;
