import gsap, { Expo, Power2 } from "gsap";
import { ScrollToPlugin, ScrollTrigger } from "gsap/all";

gsap.registerPlugin(ScrollToPlugin, ScrollTrigger);

export const scrollTo = (to) =>
  gsap.to(window, {
    duration: 1,
    scrollTo: to,
    ease: Power2.easeInOut,
  });

export const heroAnimation = () => {
  const tl = gsap.timeline();

  tl.to(".hero-upper-heading-animation", {
    duration: 2.25,
    opacity: 1,
    transform: "none",
    ease: Expo.easeOut,
  })
    .to(
      ".hero-lower-heading-animation",
      {
        duration: 2.25,
        opacity: 1,
        transform: "none",
        ease: Expo.easeOut,
      },
      "-=2.1"
    )
    .to(
      ".hero-tag",
      {
        duration: 1.5,
        opacity: 1,
        scale: 1,
        ease: Expo.easeOut,
      },
      "-=2"
    )
    .to(
      ".hero-tag-inner",
      {
        duration: 1.5,
        transform: "none",
        ease: Expo.easeOut,
      },
      "-=1.6"
    )
    .to(
      ".header",
      {
        duration: 2,
        transform: "none",
        ease: Expo.easeOut,
      },
      "-=2"
    )
    .to(
      ".hero-image",
      {
        duration: 2,
        scale: 1,
        opacity: 1,
        ease: Expo.easeOut,
      },
      "-=2"
    )
    .to(
      ".hero-gradient-bg",
      {
        duration: 10,
        scale: 1,
        opacity: 1,
        ease: Expo.easeOut,
      },
      "-=1.75"
    );
};
