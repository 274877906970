import * as sc from "../styled/scFooter";
import StarBg from "../assets/starBg.svg";
import GradientBg from "../assets/footerGradientBg.svg";
import ContactIcon from "../assets/contact.svg";
import {
  contactMessage,
  contactMailLink,
  footerSocialLinks,
} from "../contants";

const Footer = () => {
  return (
    <sc.FooterWrapper id="footer">
      <sc.FooterBg src={StarBg} />
      <sc.FooterGradientBg src={GradientBg} />
      <sc.FooterContainer>
        <sc.FooterIcon src={ContactIcon} />
        <sc.FooterText>{contactMessage}</sc.FooterText>
        <sc.FooterButton href={contactMailLink}>İletişim</sc.FooterButton>
        <sc.FooterSocialContainer>
          {footerSocialLinks.map(({ link, icon }, i) => (
            <sc.FooterSocialButton href={link} key={i}>
              <sc.FooterSocialIcon src={icon} alt="ACM Sosyal Medya Linki" />
            </sc.FooterSocialButton>
          ))}
        </sc.FooterSocialContainer>
      </sc.FooterContainer>
    </sc.FooterWrapper>
  );
};

export default Footer;
