import { useState, useEffect } from "react";
import { GlobalStyles } from "./styled/scApp";
import { heroAnimation } from "./animation";
import Sponsors from "./components/Sponsors";
import Header from "./components/Header";
import Hero from "./components/Hero";
import About from "./components/About";
import Program from "./components/Program";
import Footer from "./components/Footer";

function App() {
  const [sponsorsOpen, setSponsorsOpen] = useState(false);

  useEffect(() => {
    heroAnimation();
  }, []);

  return (
    <main id="home">
      <GlobalStyles />
      {/* <Sponsors sponsorsOpen={sponsorsOpen} setSponsorsOpen={setSponsorsOpen} /> */}
      <Header setSponsorsOpen={false} />
      <Hero />
      <About />
      <Program />
      <Footer />
    </main>
  );
}

export default App;
