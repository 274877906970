import { useState, useEffect } from "react";
import * as sc from "../styled/scProgram";
import { dates, participants } from "../contants";
import dateIcon from "../assets/dateBlue.svg";

const Program = () => {
  const [activeDate, setActiveDate] = useState(dates[0]);
  const [activeParticipant, setActiveParticipant] = useState(participants[0]);

  useEffect(() => {
    setActiveParticipant(
      participants.find((participant) => participant.date === activeDate)
    );
  }, [activeDate]);

  return (
    <sc.ProgramWrapper id="calendar">
      <sc.ProgramContainer>
        <sc.ProgramMain>
          <sc.ProgramHeading>Program</sc.ProgramHeading>
          <sc.ProgramButtonContainer>
            {dates.map((date, i) => (
              <sc.ProgramButton
                key={i}
                $active={activeDate === date}
                onClick={() => setActiveDate(date)}
              >
                {date}
              </sc.ProgramButton>
            ))}
          </sc.ProgramButtonContainer>
          <sc.ParticipantsContainer>
            {participants.map(
              (participant, i) =>
                activeDate === participant.date && (
                  <sc.Participant
                    key={i}
                    $active={activeParticipant.name === participant.name}
                    onClick={() => setActiveParticipant(participant)}
                  >
                    <sc.ParticipantImage
                      src={participant.img}
                      alt={participant.name}
                    />
                    <sc.ParticipantDetails>
                      <sc.ParticipantContent>
                        <sc.ParticipantName>
                          {participant.name}
                        </sc.ParticipantName>
                        <sc.ParticipantDescription>
                          {participant.description}
                        </sc.ParticipantDescription>
                      </sc.ParticipantContent>
                    </sc.ParticipantDetails>
                    <sc.ParticipantProgramDate>
                      <sc.ParticipantProgramDateIcon
                        src={dateIcon}
                        alt={participant.time}
                      />
                      {participant.time}
                    </sc.ParticipantProgramDate>
                  </sc.Participant>
                )
            )}
          </sc.ParticipantsContainer>
        </sc.ProgramMain>
        <sc.ProgramDetails>
          <sc.DetailsParticipantImage
            src={activeParticipant.img}
            alt={activeParticipant.name}
          />
          <sc.DetailsParticipantName>
            {activeParticipant.name}
          </sc.DetailsParticipantName>
          <sc.DetailsParticipantDescription>
            {activeParticipant.description}
          </sc.DetailsParticipantDescription>
          <sc.DetailsParticipantDivider />
          <sc.DetailsParticipantAbout>
            {activeParticipant.detail}
          </sc.DetailsParticipantAbout>
        </sc.ProgramDetails>
      </sc.ProgramContainer>
    </sc.ProgramWrapper>
  );
};

export default Program;
