import styled, { keyframes } from "styled-components";
import { colors } from "../contants";

export const FadeIn = keyframes`
  0% {
    opacity: 0;
  } 100% {
    opacity: 1;
  }
`;

export const SponsorsWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000000;
  animation: ${FadeIn} 0.5s ease forwards;
`;

export const SponsorsOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${colors.popupOverlayColor};
`;

export const SponsorsContainer = styled.div`
  width: 90%;
  max-width: 64rem;
  max-height: 90vh;
  overflow-y: scroll;
  padding: 2.5rem 5rem 5rem;
  background-color: white;
  z-index: 1;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 2rem;

  @media only screen and (max-width: 768px) {
    padding: 2.5rem;
  }
`;

export const SponsorsCloseButton = styled.button`
  position: absolute;
  top: 2rem;
  right: 2rem;
  transition: transform 0.5s ease;
  cursor: pointer;

  &:hover {
    transform: translateY(-5px);
  }

  @media only screen and (max-width: 768px) {
    top: 1rem;
    right: 1rem;
  }
`;

export const SponsorsCloseButtonImage = styled.img`
  @media only screen and (max-width: 768px) {
    transform: scale(0.8);
  }
`;

export const SponsorsHeading = styled.h1`
  background-color: ${colors.popupColor};
  padding: 2rem 4rem;
  border-radius: 1rem;
  font-size: 1.8rem;
  font-family: "Syne", "Poppins", sans-serif;
  margin-bottom: 2.5rem;

  @media only screen and (max-width: 768px) {
    font-size: 1.6rem;
  }
`;

export const SponsorsMain = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 4rem;

  @media only screen and (max-width: 768px) {
    justify-content: center;
  }
`;

export const Sponsor = styled.img`
  width: 15rem;

  @media only screen and (max-width: 768px) {
    width: 10rem;
  }
`;
