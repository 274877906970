import styled from "styled-components";
import { colors } from "../contants";

export const FooterWrapper = styled.footer`
  position: relative;
  padding: 10rem 0 0;
`;

export const FooterContainer = styled.div`
  width: 95%;
  max-width: 115rem;
  margin: 0 auto;
  height: 28rem;
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const FooterIcon = styled.img``;

export const FooterText = styled.p`
  font-size: 1.5rem;
  color: ${colors.textColor};
  max-width: 50rem;
  width: 95%;
  line-height: 1.8;
  margin: 2rem 0;
  text-align: center;
`;

export const FooterButton = styled.a`
  color: white;
  background-color: ${colors.primaryColor};
  padding: 1.4rem 4rem;
  font-size: 1.4rem;
  font-weight: 400;
  border-radius: 1rem;
  text-decoration: none;
  transition: background-color 0.5s ease;

  &:hover {
    background-color: ${colors.secondaryColor};
  }
`;

export const FooterSocialContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 6rem;
`;

export const FooterSocialButton = styled.a`
  transition: transform 0.5s ease;

  &:hover {
    transform: translateY(-5px);
  }

  &:not(:last-child) {
    margin-right: 2rem;
  }
`;

export const FooterSocialIcon = styled.img``;

export const FooterBg = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 100rem;
`;

export const FooterGradientBg = styled.img`
  position: absolute;
  bottom: -5rem;
  left: 0;
  width: 100%;
`;
